import React, {FC, FocusEvent, SyntheticEvent, useEffect, useState} from 'react';
import {useNavigate} from 'react-router';
import {Box, Link, TextField, Typography} from '@mui/material';
import Button from '@mui/lab/LoadingButton';
import {Link as RouterLink} from 'react-router-dom';

import {useLoginMutation} from '@/stores/api/auth';
import {RoutePaths} from '@/shared/constants/route';
import {loginFileStorage} from '@/stores/fileStorage/api';
import {setToken} from '@/stores/FileStorageStore';
import {useAppDispatch} from '@/stores/hooks';
import {parseJwt} from '@/shared/utils/authUtils';
import {supportDepartmentId} from '@/shared/constants/departmentConstants';
import {setAuthTokens, setUserInfo} from '@/stores/AuthStore';
import {formatKeys} from '@/shared/utils/responseUtils.ts';
import {IUser, IUserResponse} from '@/shared/models';

const inputStyles = {
  input: {
    bgcolor: 'auth.main',
    padding: 5
  }
};

const LoginPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [submitAuth, {isLoading, error}] = useLoginMutation();
  const [errorText, setErrorText] = useState<string | null>(null);

  const errorFields = {
    username: false,
    password: false
  };

  const handleS3Storage = async () => {
    const response = await loginFileStorage();

    if ('token' in response) {
      // @ts-ignore
      dispatch(setToken(response.token));
    }
  };

  const handleSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = new FormData(e.currentTarget);
    const username = `${data.get('username')}`;

    data.set('username', username.toLowerCase());

    const result = await submitAuth(data);

    if ('data' in result) {
      handleS3Storage();

      // если пользователь из ТП, показать дефолтную страницу со списком задач из ТП
      try {
        const accessToken = parseJwt(result?.data?.accessToken || '');

        if (accessToken?.sub) {
          const userResponse = JSON.parse(accessToken.sub);
          const userInfo = formatKeys<IUserResponse, IUser>(userResponse);
          dispatch(setUserInfo(userInfo));
          dispatch(
            setAuthTokens({
              accessToken: result?.data?.accessToken,
              refreshToken: result?.data?.refreshToken
            })
          );

          if (
            userResponse.user_departments.find(
              ({department_id}: {department_id?: number}) => department_id === supportDepartmentId
            )
          ) {
            return navigate(RoutePaths.MainTechSupport);
          }
        }
      } catch (e) {
        // error
      }

      navigate(RoutePaths.Main);
    }
  };

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    setErrorText(null);

    return e;
  };

  useEffect(() => {
    if (error) {
      setErrorText('Неправильный логин или пароль');
    }
  }, [error]);

  return (
    <form onSubmit={handleSubmit}>
      <Box mb={10} color={'error.main'}>
        <Typography variant="body2">{errorText || '\u00A0'}</Typography>
      </Box>
      <Box mb={14}>
        <TextField
          id="username"
          name="username"
          required
          error={errorFields.username}
          fullWidth
          autoComplete="email"
          helperText={errorFields.username && 'Неправильный логин'}
          placeholder="Логин"
          color="secondary"
          sx={{
            marginBottom: '2rem',
            ...inputStyles
          }}
          inputProps={{
            onFocus: handleFocus
          }}
        />
        <TextField
          type="password"
          id="password"
          name="password"
          required
          placeholder="Пароль"
          autoComplete="current-password"
          error={errorFields.password}
          fullWidth
          helperText={errorFields.password && 'Неправильный пароль'}
          color="primary"
          sx={inputStyles}
          inputProps={{
            onFocus: handleFocus
          }}
        />
        <Box textAlign="right" mt={5}>
          <Link
            component={RouterLink}
            to={RoutePaths.ForgotPassword}
            color="auth.contrastText"
            underline="always"
            sx={{
              fontSize: '1.2rem'
            }}
          >
            Забыли пароль?
          </Link>
        </Box>
      </Box>
      <Button
        type="submit"
        variant="contained"
        loading={isLoading}
        loadingIndicator="Выполняется вход…"
        fullWidth
      >
        Войти
      </Button>
    </form>
  );
};

export default LoginPage;
