export enum RoutePaths {
  // Home = '/',
  ForgotPassword = '/forgot-password',
  Main = '/main',
  // MainTasks = '/main/tasks',
  MainSubtasks = '/main/subtasks',
  MainTechSupport = '/main/support-tasks',
  Tasks = '/appeals',
  TaskPage = '/task/:taskId',
  SubtaskPage = '/subtask/:taskId',
  SupportTaskPage = '/support-task/:taskId',
  LoginPage = '/auth',
  NotFound = '/notfound',
  Rate = '/rate/:hashId',
  Chats = '/chats',
  ChatPage = '/chats/:chatId'
}
