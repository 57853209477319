import React, {FC, memo, useCallback, useEffect, useRef, useState} from 'react';
import {
  Box,
  Checkbox,
  Fade,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  styled,
  Tooltip,
  Typography
} from '@mui/material';
import {CheckCircle, KeyboardArrowDown} from '@mui/icons-material';
import {useSelector} from 'react-redux';

import {IMessage} from '@/shared/models/messageModel';
import {IFile} from '@/shared/models/fileModel';
import {checkSize} from '@/shared/utils/fileUtils';
import {ETaskStatuses} from '@/shared/constants/taskStatuses';
import prepareAddFiles from '@/shared/utils/addFiles';
import {chatErrorSelector} from '@/stores/TaskChatStore/TaskChatSelector';
import {setChatErrorMessage} from '@/stores/TaskChatStore/TaskChatStore';
import {useAppDispatch} from '@/stores/hooks';
import {ErrorTaskCondition} from '@/modals/confirmation/ErrorTaskCondition';
import {IChatMessage} from '@/shared/models/chatModel';

import {ChatTextField} from './components/ChatTextField/index';
import {ChatFileList} from './components/ChatFileList/index';
import {ChatMessage} from './components/ChatMessage/index';
import s from './Chat.module.css';

const StyledIconButton = styled(IconButton)(() => ({
  '&:hover': {
    color: 'white',
    transition: 'color 0.2s ease-in-out'
  },
  color: 'var(--nav-color-inactive)'
}));

interface IProps {
  messages: IMessage[] | IChatMessage[];
  status?: number;
  isDisabled?: boolean;
  isLoadingMessages?: boolean;
  isLoadingSubmit?: boolean;
  hasAllButton?: boolean;
  isDisabledSend?: boolean;
  isDisabledSendAll?: boolean;
  isDisabledInput?: boolean;
  isDisabledInputFile?: boolean;
  isDisplaySystemComments: boolean;
  isSendAll?: boolean;
  onMessageSend: (message: string, files: IFile[], toAll?: boolean) => void;
  onGetFile: (file: IFile) => void;
  confirmContent?: () => React.ReactNode;
  isTaskChat?: boolean;
  isMessageChat?: boolean;
  source?: number;
  chatId?: string;
  isLoadingPage?: boolean;
  customHandleScroll?: (chatRef: React.MutableRefObject<HTMLUListElement | null>) => void;
  isSelectingChatMessages?: boolean;
  setSelectedChatMessages?: (messages: (prevSelected: any[]) => any[]) => void;
}

const Chat: FC<IProps> = ({
  messages = [],
  status,
  isDisabled,
  isLoadingMessages,
  isLoadingSubmit,
  hasAllButton,
  isDisabledSend: initialIsDisabledSend = false,
  isDisabledSendAll: initialIsDisabledSendAll = false,
  isDisabledInput,
  isDisabledInputFile,
  isDisplaySystemComments,
  isSendAll = false,
  onMessageSend,
  onGetFile,
  confirmContent,
  isTaskChat = false,
  isMessageChat = false,
  source,
  chatId,
  isLoadingPage = false,
  customHandleScroll,
  isSelectingChatMessages,
  setSelectedChatMessages
}) => {
  const [message, setMessage] = useState('');
  const chatRef = useRef<HTMLUListElement | null>(null);
  const [files, setFiles] = useState<IFile[]>([]);
  const [errorFileSize, setErrorFileSize] = useState<string | null>(null);
  const [openCondition, setOpenCondition] = useState<boolean>(false);
  const [isDisabledSend, setIsDisabledSend] = useState<boolean>(initialIsDisabledSend);
  const [isDisabledSendAll, setIsDisabledSendAll] = useState<boolean>(initialIsDisabledSendAll);
  const [previousMessagesLength, setPreviousMessagesLength] = useState(0);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [isCheckedChatMessageIds, setIsCheckedChatMessageIds] = useState<{[key: string]: boolean}>(
    {}
  );

  const dispatch = useAppDispatch();

  const chatErrorMessages = useSelector(chatErrorSelector);

  useEffect(() => {
    if (status) {
      dispatch(setChatErrorMessage(null));
    }
  }, [status, dispatch]);

  const minLengthCommentError = 'Минимальная длина сообщения 10 символов';

  const handleCheckMinLengthMessage = useCallback(
    (message: string) => {
      if (!isTaskChat) {
        return;
      }

      setMessage(message);
    },
    [isTaskChat]
  );

  const takeInWorkError = 'Возьмите задачу в работу';

  const handleCheckStatus = useCallback(
    (message: string) => {
      if (!isTaskChat) {
        return;
      }

      const isStatusNew = status === ETaskStatuses.New;

      if (isStatusNew) {
        setOpenCondition(true);
        dispatch(setChatErrorMessage([takeInWorkError]));
      } else {
        setMessage(message);
      }
    },
    [dispatch, isTaskChat, status]
  );

  useEffect(() => {
    if (!isTaskChat) {
      return;
    }

    const shouldDisableSend = message.replace(/(<([^>]+)>)/gi, '').trim().length < 10;
    const shouldDisableSendAll = !shouldDisableSend ? !isSendAll : shouldDisableSend;

    if (!shouldDisableSend) {
      dispatch(setChatErrorMessage(null));
    }

    if (
      shouldDisableSend &&
      (!chatErrorMessages || chatErrorMessages.indexOf(minLengthCommentError) === -1)
    ) {
      dispatch(setChatErrorMessage([minLengthCommentError]));
    }

    setIsDisabledSend(shouldDisableSend);
    setIsDisabledSendAll(shouldDisableSendAll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, message, isTaskChat, isSendAll]);

  const handleMessageSend = useCallback(
    (message: string, toAll?: boolean) => {
      onMessageSend(message, files, toAll);
      setFiles([]);
      dispatch(setChatErrorMessage(null));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chatId, files, status]
  );

  const handleDeleteFile = (idx: number) => {
    if (files) {
      const newFiles = [...files.slice(0, idx), ...files.slice(idx + 1)];

      setFiles(newFiles);
    }
  };

  const handleAttachFiles = useCallback(async (files: FileList) => {
    if (files) {
      const formattedFiles = await prepareAddFiles(files);
      if (formattedFiles) {
        setFiles(state => [...state, ...formattedFiles]);
      }
    }
  }, []);

  useEffect(() => {
    dispatch(setChatErrorMessage(null));
    setErrorFileSize(null);

    if (files) {
      const isSendEmail = source === 1 || source === 3;
      const errorMessage = checkSize(files, isSendEmail);
      setErrorFileSize(errorMessage);
    }
  }, [files, dispatch, source]);

  const lastMessageRef = useRef<HTMLLIElement | null>(null);

  useEffect(() => {
    setPreviousMessagesLength(0);
  }, [chatId]);

  useEffect(() => {
    if (!previousMessagesLength) {
      lastMessageRef?.current?.scrollIntoView({behavior: 'auto'});
    }

    if (
      !isLoadingPage &&
      ((isMessageChat && !showScrollButton) || !isMessageChat) &&
      messages.length - previousMessagesLength == 1
    ) {
      lastMessageRef?.current?.scrollIntoView({behavior: 'smooth'});
    }

    setPreviousMessagesLength(messages.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, isMessageChat, previousMessagesLength, isLoadingPage]);

  const scrollToBottom = () => {
    if (chatRef.current) {
      chatRef.current.scrollTo({
        top: chatRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }
  };

  const handleScroll = useCallback(() => {
    if (chatRef.current) {
      const {scrollTop, scrollHeight, clientHeight} = chatRef.current;

      const isScrolledUp = scrollHeight - scrollTop - clientHeight > scrollHeight * 0.3;
      setShowScrollButton(isScrolledUp);

      if (customHandleScroll) {
        customHandleScroll(chatRef);
      }
    }
  }, [chatRef, customHandleScroll]);

  const handleSelectMessage = (message: IMessage | IChatMessage) => {
    if (setSelectedChatMessages) {
      setSelectedChatMessages(prevSelected => {
        let updatedSelected;

        if (isCheckedChatMessageIds[message.id]) {
          updatedSelected = prevSelected.filter((m: {id: number}) => m.id !== message.id);
        } else {
          updatedSelected = [...prevSelected, message];
        }

        return updatedSelected.sort((a: {id: number}, b: {id: number}) => a.id - b.id);
      });
    }

    setIsCheckedChatMessageIds(prevState => ({
      ...prevState,
      [message.id]: !prevState[message.id]
    }));
  };

  useEffect(() => {
    setIsCheckedChatMessageIds(() => {
      return {};
    });
    if (setSelectedChatMessages) {
      setSelectedChatMessages(() => {
        return [];
      });
    }
  }, [isSelectingChatMessages, setSelectedChatMessages]);

  useEffect(() => {
    const element = chatRef.current;
    if (element) {
      element.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  return (
    <Paper className={s.chat}>
      {openCondition && (
        <ErrorTaskCondition open={true} onClose={() => setOpenCondition(false)} isChat />
      )}
      <div className={s.inner}>
        <ul className={s.messages} ref={chatRef}>
          {messages.length > 0 &&
            messages.map((message, index) => (
              <li
                style={{listStyleType: 'none'}}
                key={message.id}
                ref={index === messages.length - 1 ? lastMessageRef : null}
              >
                <Stack direction="row">
                  {message.direction && isSelectingChatMessages && (
                    <Stack direction="row" alignItems={'center'}>
                      <Tooltip
                        title={
                          !(isCheckedChatMessageIds[message.id] || false)
                            ? 'Выделить'
                            : 'Не выделять'
                        }
                      >
                        <Checkbox
                          color={'secondary'}
                          checkedIcon={<CheckCircle />}
                          checked={isCheckedChatMessageIds[message.id] || false}
                          onChange={() => handleSelectMessage(message)}
                          inputProps={{'aria-label': 'Выделить'}}
                          sx={{padding: 0, marginRight: 8, flexShrink: 0}}
                        />
                      </Tooltip>
                    </Stack>
                  )}
                  <ChatMessage
                    key={message.id}
                    message={message}
                    onGetFile={onGetFile}
                    chatId={chatId}
                    isDisplaySystemComments={isDisplaySystemComments}
                    isTaskChat={isTaskChat}
                  />
                </Stack>
              </li>
            ))}
          {isLoadingMessages && (
            <li className={s.chatCheckButton}>
              <Skeleton variant="rounded" height={45} width={380} />
            </li>
          )}
        </ul>
        {showScrollButton && (
          <Tooltip title="Вниз">
            <StyledIconButton className={s.scrollButton} onClick={scrollToBottom}>
              <KeyboardArrowDown />
            </StyledIconButton>
          </Tooltip>
        )}
      </div>
      <Box className={s.chatTextField}>
        {files && <ChatFileList files={files} onDeleteFile={handleDeleteFile} />}
        <ChatTextField
          onChangeFileInput={handleAttachFiles}
          onSendClick={handleMessageSend}
          onSendAll={mes => handleMessageSend(mes, true)}
          onChangeInput={handleCheckMinLengthMessage}
          onFocusInput={handleCheckStatus}
          isLoading={isLoadingSubmit}
          isDisabled={isDisabled || !!errorFileSize}
          hasAllButton={hasAllButton}
          isDisabledSend={isDisabledSend}
          isDisabledSendAll={isDisabledSendAll}
          isDisabledInput={isDisabledInput}
          isDisabledInputFile={isDisabledInputFile}
          isSendAll={isSendAll}
          confirmContent={confirmContent}
        />
        <Fade in={!!chatErrorMessages}>
          <Typography variant="body2" color="error" className={s.errorMessage}>
            {chatErrorMessages ? chatErrorMessages.join(', ') : ''}
          </Typography>
        </Fade>
        <Fade in={!!errorFileSize}>
          <Typography variant="body2" color="error" className={s.errorFileSize}>
            {errorFileSize}
          </Typography>
        </Fade>
      </Box>
    </Paper>
  );
};

export default memo(Chat);
