import React, {FC, useEffect, useMemo, useState} from 'react';
import {CircularProgress, Collapse, Stack, Typography} from '@mui/material';
import {Link} from 'react-router-dom';

import {useGetCompanyActiveTasksQuery} from '@/stores/api/chat-page/tasks.ts';
import {IQueryArg} from '@/stores/api/chats.ts';
import {ITaskModel} from '@/shared/models/tasksDataModel.ts';
import {usePrepareDict} from '@/shared/hooks/usePrepareDict.ts';
import {formatDate} from '@/shared/utils/dateUtils.ts';
import useLinearLoading from '@/shared/hooks/useLinearLoading.ts';
import {ChatInfoButton, DividerHr, EllipsisTypography} from '@/shared/styles/chatStyles';

interface IProps {
  companyId?: number;
}

const ChatInfoTasks: FC<IProps> = ({companyId}) => {
  const {statusesIds: statusesIds} = usePrepareDict();
  const [isOpenTasks, setIsOpenTasks] = useState<boolean>(false);

  const tasksQuery = useMemo(() => {
    const page = {
      limit: 40,
      offset: 0
    };

    const filters = {
      company_id: String(companyId),
      new_and_work_tasks: 1
    };

    const params: IQueryArg = {
      page,
      filters
    };

    return params;
  }, [companyId]);

  const {
    data: tasksData,
    isLoading: isLoadingTasks,
    isFetching: isFetchingTasks
  } = useGetCompanyActiveTasksQuery(tasksQuery, {
    skip: !companyId || !tasksQuery,
    refetchOnMountOrArgChange: true
  });

  useEffect(() => {
    setIsOpenTasks(!isFetchingTasks && !isLoadingTasks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasksData]);

  useLinearLoading(isFetchingTasks || isLoadingTasks);

  return (
    <>
      <Stack paddingTop={18} paddingLeft={48} paddingRight={48}>
        <ChatInfoButton
          variant={'contained'}
          onClick={() => setIsOpenTasks(prevState => !prevState && !isFetchingTasks)}
        >
          <Typography variant="body1">Активные задачи</Typography>
        </ChatInfoButton>
      </Stack>
      {(isFetchingTasks || isLoadingTasks || !tasksData) && (
        <Stack padding={6} alignItems={'center'}>
          <CircularProgress color="inherit" />
        </Stack>
      )}
      <Stack padding={6}>
        <Collapse
          in={isOpenTasks && !isFetchingTasks && !isLoadingTasks}
          timeout="auto"
          unmountOnExit
        >
          {tasksData && (
            <>
              <Stack direction="column" spacing={2} paddingTop={8} paddingBottom={8}>
                {tasksData?.data.map((task: ITaskModel) => (
                  <React.Fragment key={task.id}>
                    <DividerHr />
                    <Stack
                      direction={'row'}
                      paddingTop={2}
                      paddingLeft={4}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="subtitle2">
                        <Link
                          to={`/task/${task.id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="link"
                        >
                          № {task.ticket}
                        </Link>
                      </Typography>
                      <Typography variant="body1" fontSize={12} textAlign="center" flexGrow={1}>
                        {statusesIds[task.taskStatusId].name}
                      </Typography>
                      <Typography variant="body1" fontSize={12} textAlign="right">
                        {task.createdAt && formatDate(task.createdAt)}
                      </Typography>
                    </Stack>
                    <Stack
                      direction={'row'}
                      paddingTop={2}
                      paddingLeft={4}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <EllipsisTypography variant={'body1'}>{task.title}</EllipsisTypography>
                    </Stack>
                  </React.Fragment>
                ))}
              </Stack>
            </>
          )}
        </Collapse>
      </Stack>
    </>
  );
};

export default ChatInfoTasks;
