import React, {FC, SyntheticEvent, useState} from 'react';
import {Box, Stack, TextField, Typography} from '@mui/material';
import Button from '@mui/lab/LoadingButton';

import {Countdown} from '@/components/Countdown';

interface CodeStepProps {
  onCodeSubmitted: (code: string) => void;
  onResendCode: () => void;
  isLoading: boolean;
  errorText: string | null;
  isTimerActive: boolean;
  needUpdateTimer: boolean;
  onTimerDone: () => void;
}

const inputStyles = {
  input: {
    bgcolor: 'auth.main',
    padding: 5
  }
};

const DEFAULT_TIMER = 5; // 5 мин

const CodeStep: FC<CodeStepProps> = ({
  onCodeSubmitted,
  onResendCode,
  isLoading,
  errorText,
  isTimerActive,
  needUpdateTimer,
  onTimerDone
}) => {
  const [code, setCode] = useState('');

  const handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    onCodeSubmitted(code);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Typography color="white" paddingY={8}>
        Введите код, который пришёл вам на почту. Если письма нет в папке “Входящие”, проверьте
        папку “Спам”
      </Typography>
      <Box mb={14}>
        <TextField
          id="code"
          name="code"
          required
          fullWidth
          placeholder="Проверочный код"
          color="secondary"
          sx={{
            marginBottom: '2rem',
            ...inputStyles
          }}
          value={code}
          onChange={e => setCode(e.target.value)}
        />
        {isTimerActive ? (
          <Stack direction="row" color="white" spacing={2}>
            <Typography>Вы можете получить новый код через</Typography>
            <Countdown
              minutes={DEFAULT_TIMER}
              seconds={0}
              needUpdate={needUpdateTimer}
              onDone={onTimerDone}
            />
          </Stack>
        ) : (
          <Button variant="outlined" fullWidth color="success" onClick={onResendCode}>
            Выслать новый код
          </Button>
        )}
      </Box>
      <Button
        type="submit"
        variant="contained"
        loading={isLoading}
        loadingIndicator="Проверка кода…"
        fullWidth
      >
        Проверить код
      </Button>
      <Box textAlign="center" color="error.main">
        <Typography paddingTop={10} variant="body2">
          {errorText || '\u00A0'}
        </Typography>
      </Box>
    </form>
  );
};

export default CodeStep;
