import React, {FC} from 'react';
import {Backdrop, Box, Button, Grid, Modal, Stack, Typography} from '@mui/material';

interface IProps {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 600,
  bgcolor: 'background.paper',
  borderRadius: '6px',
  p: 18,
  maxHeight: '100vh',
  overflowY: 'auto'
};

const DeleteTelegramLinkChatConfirmation: FC<IProps> = ({open, onSubmit, onClose}) => {
  const handleSubmit = async () => {
    onSubmit();
  };

  return (
    <Modal
      open={open}
      aria-labelledby="Удаление привязки телеграм чата"
      closeAfterTransition
      slots={{backdrop: Backdrop}}
      slotProps={{
        backdrop: {
          timeout: 500
        }
      }}
    >
      <Box sx={style}>
        <Typography variant="h2" paddingBottom={10}>
          Удаление привязки телеграм чата
        </Typography>
        <Stack alignItems={'center'}>
          <Typography variant="subtitle1" paddingBottom={10}>
            Вы уверены, что хотите удалить привязанный телеграм чат?
          </Typography>
        </Stack>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Button
              type="button"
              variant="contained"
              color="secondary"
              onClick={onClose}
              sx={{minWidth: 140}}
              disabled={false}
            >
              Отмена
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              color="error"
              disabled={false}
              onClick={handleSubmit}
              sx={{minWidth: 140}}
            >
              Удалить
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default DeleteTelegramLinkChatConfirmation;
