import React, {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {Stack} from '@mui/material';

import {
  useCreateChatMessageMutation,
  useGetChatByIdQuery,
  useGetChatMessageListQuery
} from '@/stores/api/chats';
import {Chat} from '@/components/Chat';
import {IFile} from '@/shared/models/fileModel';
import {downloadFile} from '@/shared/utils/downloadFiles';
import {useAppSelector} from '@/stores/hooks';
import {fileStorageTokenSelector} from '@/stores/FileStorageStore';
import {useChatStreaming} from '@/shared/hooks/useChatStreaming.ts';
import {ChatMessageHeader} from '@/scenes/ChatsPage/components/ChatMessageHeader/';
import {PAGE_SIZE} from '@/scenes/AllTasksPage/scenes/constants';
import {IChatMessage} from '@/shared/models/chatModel';
import {userInfoSelector} from '@/stores/AuthStore/AuthStateSelector.ts';
import useLinearLoading from '@/shared/hooks/useLinearLoading.ts';
import {sendWithFileChunks} from '@/shared/utils/addFiles.ts';

interface IProps {
  chatId?: string;
  setIsFetchingMessages: (value: boolean) => void;
  isOpenChatInfo: boolean;
  setIsOpenChatInfo: (value: (prev: boolean) => boolean) => void;
}

const ChatMessageSection: FC<IProps> = ({
  chatId,
  setIsFetchingMessages,
  isOpenChatInfo,
  setIsOpenChatInfo
}) => {
  const s3Token = useAppSelector(fileStorageTokenSelector);
  const currentUser = useAppSelector(userInfoSelector);
  const [submitMessage, {isLoading: isLoadingSubmit}] = useCreateChatMessageMutation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [messages, setMessages] = useState<IChatMessage[]>([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: PAGE_SIZE
  });
  const [hasMore, setHasMore] = useState(true);
  const [isLoadingPage, setIsLoadingPage] = useState(true);
  const [totalPage, setTotalPage] = useState(0);
  const [isSelectingChatMessages, setIsSelectingChatMessages] = useState<boolean>(false);
  const [selectedChatMessages, setSelectedChatMessages] = useState<IChatMessage[]>([]);

  const {
    data: chatData,
    isLoading: isLoadingChat,
    isFetching: isFetchingChat
  } = useGetChatByIdQuery(
    {chatId},
    {
      skip: !chatId,
      refetchOnMountOrArgChange: true
    }
  );

  const chatMessagesQuery = useMemo(
    () => ({
      chatId: chatId || '',
      page: {
        limit: paginationModel.pageSize,
        offset: paginationModel.page * paginationModel.pageSize
      },
      attributes: {
        user_is_read: currentUser.id
      }
    }),
    [chatId, currentUser, paginationModel.page, paginationModel.pageSize]
  );

  useChatStreaming(chatMessagesQuery);

  const {
    data: messagesData,
    isLoading: isLoadingMessages,
    isFetching: isFetchingMessages,
    refetch: refetchMessages
  } = useGetChatMessageListQuery(chatMessagesQuery, {
    skip: !chatId || !chatMessagesQuery,
    refetchOnMountOrArgChange: true
  });

  useEffect(() => {
    setTotalPage(0);
    setIsOpen(false);
    setMessages([]);
    setPaginationModel({
      page: 0,
      pageSize: PAGE_SIZE
    });
  }, [chatId]);

  const handleMessageSend = async (content: string, files: IFile[] = []) => {
    if (!chatId) {
      console.warn('Чат не выбран');
      return;
    }

    if (content.length > 0 || files.length > 0) {
      const submitParams = {chatId};

      await sendWithFileChunks({
        content,
        attachments: files,
        submitFunction: submitMessage,
        submitParams
      });
    }
  };

  const handleGetFile = async (file: IFile) => {
    await downloadFile(file, s3Token);
  };

  useEffect(() => {
    if (messagesData?.data) {
      const messageIds = new Set(messages.map(message => message.id));
      const newMessages = messagesData.data.filter(
        message => !messageIds.has(message.id) && !message.isWebsocket
      );
      const websocketMessages = messagesData.data.filter(
        message => !messageIds.has(message.id) && message.isWebsocket
      );
      const reversedMessages = [...newMessages].reverse();

      setMessages(prevMessages => {
        const updatedMessages = prevMessages.map(prevMessage => {
          const newMessage = messagesData.data.find(message => message.id === prevMessage.id);
          return newMessage ? newMessage : prevMessage;
        });
        const unionMessages =
          totalPage > 0
            ? [...reversedMessages, ...updatedMessages]
            : [...updatedMessages, ...reversedMessages];

        return [...unionMessages, ...websocketMessages];
      });

      setHasMore(
        messagesData.data.length >= PAGE_SIZE && messages.length !== messagesData.meta.totalCount
      );
      setIsLoadingPage(false);
      setIsOpen(true);

      if (websocketMessages.length > 0) {
        refetchMessages();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messagesData]);

  const handleScroll = useCallback(
    (chatRef: React.MutableRefObject<HTMLUListElement | null>) => {
      if (chatRef.current) {
        const {scrollTop, scrollHeight, clientHeight} = chatRef.current;
        const isScrolledUp = scrollTop <= scrollHeight * 0.1;
        const isScrolledDown = scrollTop + clientHeight >= scrollHeight - 2 * totalPage;

        if (!scrollTop && isLoadingPage) {
          chatRef.current.scrollTop = scrollHeight * 0.1;
        }

        if (!isFetchingMessages && !isLoadingMessages && !isLoadingPage) {
          if (isScrolledUp && hasMore) {
            setIsLoadingPage(true);
            setPaginationModel(prev => ({
              ...prev,
              page: totalPage + 1
            }));
            setTotalPage(totalPage + 1);
          }

          if (isScrolledDown && paginationModel.page !== 0) {
            setIsLoadingPage(true);
            setPaginationModel(prev => ({
              ...prev,
              page: 0
            }));
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasMore, isLoadingMessages, isFetchingMessages, isLoadingPage, totalPage]
  );

  useEffect(() => {
    setIsFetchingMessages(isLoadingMessages || isFetchingMessages);
  }, [setIsFetchingMessages, isFetchingMessages, isLoadingMessages]);

  useEffect(() => {
    setSelectedChatMessages([]);
  }, [chatId]);

  useLinearLoading(
    isLoadingChat || isFetchingChat || isLoadingMessages || isFetchingMessages || isLoadingPage
  );

  return (
    <>
      {isOpen && (
        <>
          <Stack direction="column" sx={{flexGrow: 1}}>
            <ChatMessageHeader
              chatData={chatData}
              chatId={chatId}
              isOpenChatInfo={isOpenChatInfo}
              setIsOpenChatInfo={setIsOpenChatInfo}
              isLoadingPage={isLoadingPage}
              setIsSelectingChatMessages={setIsSelectingChatMessages}
              selectedChatMessages={selectedChatMessages}
              onSubmit={refetchMessages}
            />
            <Chat
              messages={messages}
              isLoadingMessages={isLoadingChat || isLoadingMessages || isLoadingPage}
              isLoadingSubmit={isLoadingSubmit}
              isDisabled={false}
              onMessageSend={handleMessageSend}
              onGetFile={handleGetFile}
              isTaskChat={true}
              isDisabledSend={true}
              isDisabledSendAll={false}
              isDisabledInput={false}
              isDisabledInputFile={false}
              isDisplaySystemComments={false}
              isSendAll={false}
              chatId={chatId}
              isMessageChat={true}
              isLoadingPage={isLoadingPage}
              customHandleScroll={handleScroll}
              isSelectingChatMessages={isSelectingChatMessages}
              setSelectedChatMessages={setSelectedChatMessages}
            />
          </Stack>
        </>
      )}
    </>
  );
};

export default ChatMessageSection;
