import React, {FC, useState} from 'react';
import {Box} from '@mui/material';
import {generatePath, useNavigate} from 'react-router';

import {
  useGenerateCodeMutation,
  useRecoveryPasswordMutation,
  useValidateCodeMutation
} from '@/stores/api/forgot-password';
import {useLoginMutation} from '@/stores/api/auth';
import {useAppDispatch} from '@/stores/hooks';
import {setAuthTokens, setUserInfo} from '@/stores/AuthStore';
import {parseJwt} from '@/shared/utils/authUtils';
import {formatKeys} from '@/shared/utils/responseUtils';
import {IUser, IUserResponse} from '@/shared/models';
import {RoutePaths} from '@/shared/constants/route.ts';
import {EmailStep} from '@/scenes/ForgotPasswordPage/components/EmailStep';
import {CodeStep} from '@/scenes/ForgotPasswordPage/components/CodeStep';
import {PasswordStep} from '@/scenes/ForgotPasswordPage/components/PasswordStep';

enum EMode {
  Email = 'email',
  Code = 'code',
  Password = 'password'
}

const ForgotPasswordPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [mode, setMode] = useState<EMode>(EMode.Email);
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [errorText, setErrorText] = useState<string | null>(null);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [needUpdateTimer, setNeedUpdateTimer] = useState(false);

  const [generateCode, {isLoading: isGeneratingCode}] = useGenerateCodeMutation();
  const [validateCode, {isLoading: isValidatingCode}] = useValidateCodeMutation();
  const [recoveryPassword, {isLoading: isRecoveringPassword}] = useRecoveryPasswordMutation();
  const [login, {isLoading: isLoggingIn}] = useLoginMutation();

  const handleSendCode = async (submittedEmail: string) => {
    setEmail(submittedEmail);
    setErrorText(null);

    try {
      await generateCode({email: submittedEmail}).unwrap();
      setMode(EMode.Code);
      setIsTimerActive(true);
      setNeedUpdateTimer(prev => !prev);
    } catch (err: any) {
      if (err?.data === 'User not found' || err?.data === 'Inactive user') {
        setErrorText('Пользователь с таким email не найден. Обратитесь в техническую поддержку.');
      } else {
        setErrorText('Не удалось отправить код. Пожалуйста, проверьте введенный email.');
      }
    }
  };

  const handleResendCode = async () => {
    try {
      await generateCode({email}).unwrap();
      setIsTimerActive(true);
      setNeedUpdateTimer(prev => !prev);
    } catch (err) {
      setErrorText('Не удалось повторно отправить код. Пожалуйста, попробуйте позже.');
    }
  };

  const handleValidateCode = async (submittedCode: string) => {
    setCode(submittedCode);
    setErrorText(null);

    try {
      await validateCode({code: submittedCode}).unwrap();
      setMode(EMode.Password);
    } catch (err) {
      setErrorText('Неверный код. Пожалуйста, попробуйте снова.');
    }
  };

  const handleResetPassword = async (password: string) => {
    setErrorText(null);

    try {
      await recoveryPassword({code, password}).unwrap();

      const formData = new FormData();
      formData.append('username', email);
      formData.append('password', password);

      const result = await login(formData).unwrap();

      const accessToken = parseJwt(result.accessToken || '');
      if (accessToken?.sub) {
        const userResponse = JSON.parse(accessToken.sub);
        const userInfo = formatKeys<IUserResponse, IUser>(userResponse);
        dispatch(setUserInfo(userInfo));
        dispatch(
          setAuthTokens({
            accessToken: result.accessToken,
            refreshToken: result.refreshToken
          })
        );
      }

      const path = generatePath(RoutePaths.Main);
      navigate(path);
    } catch (err) {
      setErrorText('Не удалось восстановить пароль. Пожалуйста, проверьте введенные данные.');
    }
  };

  const handleTimerDone = () => {
    setIsTimerActive(false);
  };

  return (
    <Box>
      {mode === EMode.Email && (
        <EmailStep
          onEmailSubmitted={handleSendCode}
          isLoading={isGeneratingCode}
          errorText={errorText}
        />
      )}
      {mode === EMode.Code && (
        <CodeStep
          onCodeSubmitted={handleValidateCode}
          onResendCode={handleResendCode}
          isLoading={isValidatingCode}
          errorText={errorText}
          isTimerActive={isTimerActive}
          needUpdateTimer={needUpdateTimer}
          onTimerDone={handleTimerDone}
        />
      )}
      {mode === EMode.Password && (
        <PasswordStep
          onPasswordSubmitted={handleResetPassword}
          isLoading={isRecoveringPassword || isLoggingIn}
          errorText={errorText}
        />
      )}
    </Box>
  );
};

export default ForgotPasswordPage;
