import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
  CreateAxiosDefaults,
  InternalAxiosRequestConfig,
  isAxiosError
} from 'axios';
import {toast} from 'react-toastify';
import {BaseQueryFn, createApi} from '@reduxjs/toolkit/query/react';

import {refreshTokenCredentials, requestValidAccessToken} from '@/shared/utils/authUtils';
import {store} from '@/stores';

export interface IAxiosBaseQuery {
  url: string;
  method: AxiosRequestConfig['method'];
  data?: AxiosRequestConfig['data'];
  params?: AxiosRequestConfig['params'];
  headers?: IAxiosHeader;
  options?: {
    skipAuth?: boolean;
    skipMessage?: boolean;
  };
  retry?: boolean;
}

interface IAxiosHeader extends AxiosRequestHeaders {
  Authorization: string;
}

// const REQUEST_TIMEOUT_ERROR = 50000;

const cancelTokenSource = axios.CancelToken.source();
const baseUrl = import.meta.env.APP_BASE_URL || '';

const apiClient = axios.create({
  // timeout: REQUEST_TIMEOUT_ERROR,
  timeoutErrorMessage: 'Request timeout error',
  validateStatus: (status: number) => status < 500,
  cancelToken: cancelTokenSource.token
} as CreateAxiosDefaults);

const axiosBaseQuery =
  ({baseUrl}: {baseUrl: string} = {baseUrl: ''}): BaseQueryFn<IAxiosBaseQuery> =>
  async ({url, method, data, params, options}) => {
    try {
      const result = await apiClient.request({
        url: baseUrl + url,
        method,
        data,
        params,
        options
      } as AxiosRequestConfig);

      return {data: result.data};
    } catch (axiosError) {
      const err = axiosError as AxiosError;

      const error = {
        status: err.response?.status || err.code,
        // @ts-ignore
        data: err.response?.data?.detail || err.message
      };

      return {error};
    }
  };

const onRequest = async (
  config: InternalAxiosRequestConfig
): Promise<InternalAxiosRequestConfig> => {
  if (config?.options?.skipAuth) {
    return config;
  }

  // request valid accessToken
  const accessToken = await requestValidAccessToken();
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
};

const onErrorResponse = (error: AxiosError | Error): Promise<AxiosError> => {
  if (isAxiosError(error)) {
    const {message} = error;
    const {method, url} = error.config as AxiosRequestConfig;
    const {status} = (error.response as AxiosResponse) ?? {};

    console.warn(`[API] ${method?.toUpperCase()} ${url} | Error ${status} ${message}`);
  } else {
    console.warn(`[API] | Error ${error.message}`);
  }

  return Promise.reject(error);
};

const baseQuery = axiosBaseQuery({baseUrl});

export const api = createApi({
  reducerPath: 'api',
  baseQuery,
  tagTypes: [
    'TASKS',
    'SUBTASKS',
    'SUPPORT_TASKS',
    'COMPANIES',
    'EMPLOYEES',
    'TASK',
    'SUBTASK',
    'SUPPORT_TASK',
    'COMPANY',
    'DEPARTMENTS',
    'TASK_TYPES',
    'UNITS',
    'USERS',
    'CHAT',
    'CHAT_LIST',
    'CHAT_GROUP_LIST',
    'CHAT_MESSAGE_LIST',
    'STATISTIC_CHATS'
  ],
  endpoints: () => ({})
});

apiClient.interceptors.request.use(onRequest, onErrorResponse);

apiClient.interceptors.response.use(
  async (response: any) => {
    const {
      data: {detail},
      status,
      config
    } = response;

    if (status == 401 && !config?.options?.skipAuth) {
      console.debug('401');
      const originalRequest = response.config;

      if (!originalRequest.retry) {
        originalRequest.retry = true;
        let data = null;
        const state = store.getState();

        if (!state.authState.accessToken) {
          return null;
        }

        const refreshToken = state.authState.refreshToken;

        try {
          // try refresh token event exp more than now
          data = await refreshTokenCredentials(refreshToken);

          if (data) {
            originalRequest.headers.Authorization = `Bearer ${data.accessToken}`;

            return await apiClient.request(originalRequest);
          }
        } catch (error: any) {
          // relogin?
        }

        // TODO: logout
        localStorage.removeItem('persist:root');
        location.reload();
        throw new Error('User is not authorized');
      }
    } else if (detail) {
      // error
      if (!config?.options?.skipMessage) {
        if (status === 403) {
          toast.error('Недостаточно прав для редактирования');
        } else {
          toast.error('Ошибка');
        }
      }
      throw new Error(detail);
    }

    return response;
  },
  axiosError => {
    const err = axiosError as AxiosError;

    const error = {
      status: err.code,
      data: err.message
    };

    toast.error(err.message);
    return Promise.reject(error);
  }
);
