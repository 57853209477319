export const prepareFieldsQuery = (obj: Record<string, any>) => {
  const result: string[] = [];

  function recursiveKeys(obj: Record<string, any>, propStr = '') {
    Object.entries(obj).forEach(([key, val]) => {
      const nestedPropStr = propStr + (propStr ? '.' : '') + key;
      if (typeof val === 'object') {
        recursiveKeys(val, nestedPropStr);
      } else {
        result.push(nestedPropStr);
      }
    });
  }

  recursiveKeys(obj);
  return result.join(',');
};

/*
  Набор запрашиваемых полей для поля field в запросах
 */
export const userDefaultFields = {
  id: 1,
  first_name: 1,
  second_name: 1,
  middle_name: 1,
  email: 1
};

const itemDefaultFields = {
  id: 1,
  name: 1
};

const user = {
  user_id: 1,
  user: userDefaultFields
};

const employee = {
  employee_id: 1,
  employee: {
    ...userDefaultFields,
    is_spam: 1,
    customer_id: 1
  }
};

const company = {
  company_id: 1,
  company: {
    ...itemDefaultFields,
    company_type_id: 1,
    bitrix_id: 1,
    is_important: 1
  }
};

const fromEmployee = {
  from_employee_id: 1,
  from_employee: {
    ...userDefaultFields,
    is_spam: 1
  }
};

const latestChatMessage = {
  id: 1,
  latest_chat_message: {
    id: 1,
    created_at: 1,
    content: 1,
    user: userDefaultFields,
    customer: userDefaultFields
  }
};

const comments = {
  task_comments: {
    employee_id: 1,
    employee: userDefaultFields,
    user_id: 1,
    is_has_files: 1
    // user: userDefaultFields
  }
};

const subtaskComments = {
  subtask_comments: {
    user_id: 1,
    user: userDefaultFields,
    is_has_files: 1
  }
};

const supportTaskComments = {
  support_task_comments: {
    user_id: 1,
    user: userDefaultFields,
    is_has_files: 1
  }
};

const author = {
  author_id: 1,
  author: userDefaultFields
};

const parentTask = {
  task_id: 1,
  task: {
    id: 1,
    title: 1
  }
};

export const userFields = prepareFieldsQuery(user);
export const taskCommentsFields = prepareFieldsQuery(comments);
export const employeeFields = prepareFieldsQuery(employee);
export const companyFields = prepareFieldsQuery(company);
export const fromEmployeeFields = prepareFieldsQuery(fromEmployee);
export const toCopyEmployeeIds = prepareFieldsQuery({
  to_copy_employees_ids: 1
  // to_copy_employees: 1
});
export const employeesFields = prepareFieldsQuery({
  employees: userDefaultFields
});

export const subtaskCommentsFields = prepareFieldsQuery(subtaskComments);
export const supportTaskCommentsFields = prepareFieldsQuery(supportTaskComments);
export const authorFields = prepareFieldsQuery(author);
export const parentTaskFields = prepareFieldsQuery(parentTask);
export const latestChatMessageFields = prepareFieldsQuery(latestChatMessage);
