import {api} from '@/stores/api/index';
import {formatKeys} from '@/shared/utils/responseUtils';
import {IStatistic, IStatisticResponse} from '@/shared/models/statisticModel';

export const apiWithStatistic = api.injectEndpoints({
  endpoints: builder => ({
    getStatisticChats: builder.query<IStatistic, void>({
      query: () => ({
        url: '/v1/statistics/chats',
        method: 'get',
        options: {
          skipMessage: true
        }
      }),

      transformResponse: (result: {data: IStatisticResponse}): IStatistic => {
        if (result?.data) {
          return formatKeys<IStatisticResponse, IStatistic>(result.data);
        }
        return {
          chats: {
            unreadChatMessagesCount: 0
          }
        };
      },

      providesTags: (_result, _error) => {
        return ['STATISTIC_CHATS'];
      }
    })
  }),
  overrideExisting: true
});

export const {useGetStatisticChatsQuery} = apiWithStatistic;
