import React, {FC, memo, useCallback, useEffect, useMemo, useState} from 'react';
import cn from 'classnames';

import {ComboBox} from '@/components/UIKit';
import {useFetchCompaniesMutation} from '@/stores/api/companies';
import {employeesFields} from '@/shared/utils/requestParamsUtils';
import {ICompany} from '@/shared/models/companyModel';
import {ITask} from '@/shared/models/tasksDataModel';
import {useAppDispatch, useAppSelector} from '@/stores/hooks';
import {
  taskCompanySelector,
  updateCompany,
  updateDepartment,
  updateEmployee
} from '@/stores/TaskSettingsStore';
import {ISubtask} from '@/shared/models/subtaskModel';
import {useDebounce} from '@/shared/hooks/useDebounce';
import {companyIndividualTypeIdSelector} from '@/stores/TaskConditionsStore/TaskConditionsSelector';
import {checkCompanyIndividual} from '@/shared/utils/companyUtils';
import {companyNotDefined} from '@/shared/constants/companyConstants';
import {useGetDepartmentsQuery} from '@/stores/api/filtered-dictionaries';
import {departmentNBUName} from '@/shared/constants/departmentConstants';
import {ICommonArgs} from '@/shared/models/commonModel';

interface IProps {
  task?: ITask | ISubtask;
  isDisabled?: boolean;
}

const PAGE_SIZE = 50;

const TaskCompanyBox: FC<IProps> = ({task, isDisabled}) => {
  const dispatch = useAppDispatch();
  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<ICompany[]>([]);
  const company = useAppSelector(taskCompanySelector);
  const companyIndividualType = useAppSelector(companyIndividualTypeIdSelector);
  const isTaskCompanyNotDefined = companyNotDefined === task?.company?.name;
  const isIndividualCompany = checkCompanyIndividual(
    companyIndividualType,
    company?.companyTypeId,
    company?.name
  );

  const loading = open && options.length === 0;

  const [fetchCompanies, {isLoading: isLoadingList}] = useFetchCompaniesMutation();

  /**
   * Когда у задачи стоит "Клиент не определён" и отдел "Не определён"
   * При изменении клиента на нужного, нужно в запросе обновления клиента
   * и сотрудника слать department_id отдела "НБУ" юнита определенного клиента.
   */
  const departmentsIds = useMemo(() => {
    if (!company?.companyResponsibles) {
      return;
    }
    return company.companyResponsibles.map(({departmentId}) => departmentId);
  }, [company?.companyResponsibles]);

  const {departmentNBU} = useGetDepartmentsQuery(
    {
      'filters[id]': 'in|' + departmentsIds
    },
    {
      skip: !departmentsIds || !isTaskCompanyNotDefined,
      selectFromResult: ({data = []}) => ({
        departmentNBU: data.find(o => o.name === departmentNBUName)
      })
    }
  );

  const query = useMemo(() => {
    const queryParams: ICommonArgs = {
      include: 'employees,company_responsibles',
      fields: employeesFields,
      filters: {
        company_type_id: 1,
        is_has_employees: 1,
        active: 1
      },
      page: {
        limit: PAGE_SIZE,
        offset: 0
      }
    };

    if (inputValue && inputValue !== company?.name) {
      queryParams.find = inputValue;
    }

    if (task && 'chatId' in task && task?.chatId) {
      if (queryParams.filters && task?.employee?.customerId) {
        queryParams.filters.has_customer = task?.employee?.customerId;
      }
    }

    /**
     * В карточке задачи если задача упала на физ. лицо (Клиент company_type_id=2),
     * в запрос клиентов в селекторе нужно добавить filters[has_employee_email]={email отправителя}
     */
    if (isIndividualCompany && task?.fromEmployee?.email) {
      queryParams['filters[has_employee_email]'] = task.fromEmployee.email;
    }

    return queryParams;
  }, [inputValue, company?.name, isIndividualCompany, task]);

  /**
   * COMPANY LIST
   */

  const loadCompanies = useCallback(
    async (active = true) => {
      try {
        const response = await fetchCompanies(query);
        const companyData = 'data' in response ? response.data?.data : [];

        if (active) {
          setOptions([...companyData]);
        }
      } catch (err) {
        console.error('Ошибка загрузки компаний');
      }
    },
    [fetchCompanies, query]
  );

  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    loadCompanies(active);

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  /**
   * Поиск компании по названию
   */

  const handleChangeInput = (_event: React.SyntheticEvent, value: string, reason: string) => {
    if (reason === 'input' || (value && reason === 'reset')) {
      setInputValue(value);
    }
  };

  const debouncedValue = useDebounce(inputValue, 500);

  useEffect(() => {
    if (open) {
      loadCompanies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  /**
   * Обновить компанию => сбросить сотрудника
   * @param value
   */
  const handleChangeCompany = (value?: ICompany | null) => {
    if (!value) {
      dispatch(updateCompany());
    }
    // @ts-ignore
    dispatch(updateCompany(value));
    dispatch(updateEmployee());
  };

  // init
  // подставляем компанию задачи в выбранное значение
  useEffect(() => {
    if (!task?.company) {
      return;
    }

    dispatch(updateCompany(task.company));
  }, [dispatch, task?.company]);

  useEffect(() => {
    if (!departmentNBU) {
      return;
    }

    dispatch(updateDepartment(departmentNBU));
  }, [dispatch, departmentNBU]);

  return (
    <ComboBox<ICompany>
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      options={options}
      variantInput="standard"
      fieldLabel="Клиент"
      isLoading={isLoadingList}
      sx={{
        minWidth: '240px'
      }}
      disableClearable
      value={company || null}
      // @ts-ignore
      onChange={(_, newValues: ICompany | null) => {
        return handleChangeCompany(newValues);
      }}
      inputValue={inputValue}
      onInputChange={handleChangeInput}
      placeholder="Выберите клиента"
      filterOptions={x => x}
      filterSelectedOptions
      renderOption={({className, ...props}, option: ICompany) => {
        const isImp = checkCompanyIndividual(
          companyIndividualType,
          option.companyTypeId,
          option.name
        );
        return (
          <li {...props} className={cn(className, isImp ? 'imp' : '')} key={option.id}>
            {option.name}
          </li>
        );
      }}
      inputClassName={isIndividualCompany ? 'imp' : ''}
      disabled={isDisabled}
    />
  );
};

export default memo(TaskCompanyBox);
