import React, {FC, memo, useEffect, useMemo} from 'react';
import {Box, Button, Grid, TextField} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import cn from 'classnames';

import {FormComboBox, FormComboBoxWithConnect} from '@/components/Form/components/FormComboBox';
import {useGetDepartmentsQuery, useGetTypesQuery} from '@/stores/api/filtered-dictionaries';
import {useCreateSupportTaskMutation} from '@/stores/api/main-page/create-support-tasks';
import {AddFilesSection} from '@/components/AddFilesSection';
import {useFileState} from '@/shared/hooks/useFileState';
import useLoading from '@/shared/hooks/useLoading';
import {useCompanyLogic} from '@/modals/create/hooks/useCompanyLogic';
import {prepareDepartment} from '@/shared/utils/departmentUtils';
import {usePrepareDict} from '@/shared/hooks/usePrepareDict';
import {ISupportTaskResponse} from '@/shared/models/supportTaskModel';
import TextEditor from '@/components/TextEditor/TextEditor';
import {ICompany} from '@/shared/models/companyModel';
import {checkCompanyIndividual} from '@/shared/utils/companyUtils';
import {useAppSelector} from '@/stores/hooks';
import {companyIndividualTypeIdSelector} from '@/stores/TaskConditionsStore/TaskConditionsSelector';
import {useGetTemplatesQuery} from '@/stores/api/mail-templates';
import {userInfoSelector} from '@/stores/AuthStore/AuthStateSelector.ts';

import {useParentTasksLogic} from '../hooks/useParentTasksLogic';

import {FormValues, prepareRequestData} from './utils';

interface IProps {
  parentTaskId?: number;
  onClose: () => void;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 786,
  bgcolor: 'background.paper',
  borderRadius: '6px',
  p: 12,
  maxHeight: '100vh',
  overflowY: 'auto'
};

const CreateSupportTask: FC<IProps> = ({parentTaskId, onClose}) => {
  const [submitCreateTask, {isLoading: isSubmitLoading, isError}] = useCreateSupportTaskMutation();
  const companyIndividualType = useAppSelector(companyIndividualTypeIdSelector);
  const {unitsIds} = usePrepareDict();

  useLoading(isSubmitLoading);

  const {
    control,
    watch,
    handleSubmit,
    formState: {errors},
    setValue
  } = useForm<FormValues>();

  const watchCompany = watch('company');
  const watchType = watch('taskType');

  const {fetchCompanies, query, isCompaniesLoading} = useCompanyLogic({
    queryParams: {
      include: 'company_responsibles',
      'filters[is_has_employees]': 1
    },
    isSupportTask: true
  });

  const {filteredParentTasks} = useParentTasksLogic({
    companyId: watchCompany?.id,
    parentTaskId,
    setValue
  });

  const {departments, isDepartmentsLoading} = useGetDepartmentsQuery(
    {
      'filters[is_support]': 1
    },
    {
      selectFromResult: ({data, isFetching}) => ({
        departments: data ? data.map(val => prepareDepartment(val, unitsIds)) : [],
        isDepartmentsLoading: isFetching
      })
    }
  );

  /**
   *    Получаем Список типов
   */
  const {data: types, isLoading: isTypeLoading} = useGetTypesQuery({
    'filters[is_support_task]': 1
  });

  /**
   * Получаем список щаблонов по типу
   */

  const {data: templatesData} = useGetTemplatesQuery(
    {
      'filters[task_type_id]': `${watchType?.id}`
    },
    {
      skip: !watchType?.id
    }
  );

  useEffect(() => {
    if (departments && departments.length === 1) {
      setValue('department', departments[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departments]);

  useEffect(() => {
    const templates = templatesData?.data;
    if (templates && templates.length > 0) {
      const content = templates[0].content || '';
      setValue('content', content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templatesData?.data]);

  const {files, handleDeleteFile, handleAttachFiles} = useFileState();

  const onSubmit: SubmitHandler<FormValues> = async data => {
    if (Object.keys(errors).length > 0) {
      return;
    }

    if (data) {
      const requestData = prepareRequestData({...data, attachments: files});
      const result: {data: ISupportTaskResponse} | {error: unknown} = await submitCreateTask(
        requestData
      );

      if ('data' in result && result?.data) {
        onClose();
      }
    }
  };

  const currentUser = useAppSelector(userInfoSelector);

  const companyQuery = useMemo(() => {
    if (!currentUser?.userUnits) {
      return query;
    }
    // При создании задачи в тех. поддержку, в запрос селектора "Клиент" необходимо добавить фильтр filters[or_inner_support_company]={id подразделений через запятую текущего авторизованного пользователя из токена}
    const units = currentUser.userUnits.map(({unitId}) => `${unitId}`).join(',');
    return {
      ...query,
      'filters[or_inner_support_company]': units
    };
  }, [currentUser.userUnits, query]);

  return (
    <Box sx={style}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={12} sx={{marginBottom: 12}}>
          <Grid item xs={12} sm={6}>
            <label htmlFor="company">
              Клиент <span className="imp">*</span>
            </label>
            <FormComboBoxWithConnect
              id="company"
              name="company"
              required
              isLoading={isCompaniesLoading}
              control={control}
              placeholder="Выберите клиента"
              getData={fetchCompanies}
              query={companyQuery}
              renderOption={({className, ...props}: any, option: ICompany) => {
                const isImp = checkCompanyIndividual(
                  companyIndividualType,
                  option.companyTypeId,
                  option.name
                );
                return (
                  <li {...props} className={cn(className, isImp ? 'imp' : '')} key={option.id}>
                    {option.name}
                  </li>
                );
              }}
              inputClassName={
                checkCompanyIndividual(
                  companyIndividualType,
                  watchCompany?.companyTypeId,
                  watchCompany?.name
                )
                  ? 'imp'
                  : ''
              }
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <label htmlFor="department">
              Отдел <span className="imp">*</span>
            </label>
            <FormComboBox
              id="department"
              name="department"
              required
              isLoading={isDepartmentsLoading}
              isDisabled={departments && departments.length === 1}
              options={departments}
              control={control}
              placeholder="Выберите отдел"
            />
          </Grid>
          <Grid item xs={12}>
            <label htmlFor="title">
              Тема <span className="imp">*</span>
            </label>
            <Controller
              name="title"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: 'Обязательное поле'
                }
              }}
              defaultValue=""
              render={({field: {onChange, value}}) => (
                <TextField
                  id="title"
                  placeholder="Укажите название задачи"
                  value={value}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    onChange(event.target.value);
                  }}
                  fullWidth
                  autoComplete="off"
                  error={!!errors?.title}
                  helperText={errors?.title?.message}
                />
              )}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <label htmlFor="task">По обращению</label>
            <FormComboBox
              id="task"
              name="task"
              options={filteredParentTasks}
              control={control}
              placeholder="Выберите обращение"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <label htmlFor="taskType">
              Тип <span className="imp">*</span>
            </label>
            <FormComboBox
              id="taskType"
              name="taskType"
              required
              options={types || []}
              isLoading={isTypeLoading}
              control={control}
              placeholder="Выберите тип обращения"
            />
          </Grid>
          <Grid item xs={12}>
            <label htmlFor="content">
              Описание задачи <span className="imp">*</span>
            </label>
            <Controller
              name="content"
              control={control}
              rules={{
                required: 'Обязательное поле',
                validate: value => {
                  const isNotEmpty = value.replace(/<[^>]*>/g, '').trim().length > 0;
                  return (
                    isNotEmpty ||
                    'Описание задачи не может быть пустым или состоять только из пробелов'
                  );
                }
              }}
              defaultValue=""
              render={({field: {onChange, value}}) => (
                <TextEditor
                  id="content"
                  value={value}
                  onChange={(value: string) => {
                    onChange(value);
                  }}
                  error={!!errors?.content}
                  helperText={errors?.content?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <AddFilesSection
              files={files}
              onDeleteFile={handleDeleteFile}
              onChangeFileInput={handleAttachFiles}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between">
          <LoadingButton
            type="submit"
            variant="contained"
            color="primary"
            loading={isSubmitLoading}
            loadingIndicator="Создание…"
            sx={{minWidth: 154}}
          >
            Создать
          </LoadingButton>
          {isError && <span className="error">Ошибка создания подзадачи</span>}
          <Button
            type="button"
            variant="outlined"
            color="secondary"
            onClick={onClose}
            sx={{minWidth: 154, borderWidth: 2}}
          >
            Отмена
          </Button>
        </Grid>
      </form>
    </Box>
  );
};

export default memo(CreateSupportTask);
