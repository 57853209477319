import {ICreateChipArg} from '@/shared/models/tasksCounters';
import {formatKeys} from '@/shared/utils/responseUtils';
import {api} from '@/stores/api';

const apiWithCreateChipsCounts = api.injectEndpoints({
  endpoints: builder => ({
    createChipsCounts: builder.query<ICreateChipArg, void>({
      query: () => ({
        url: '/v1/statistics/dashboard',
        method: 'get'
      }),
      transformResponse: (result: {data: ICreateChipArg}) => {
        return formatKeys(result.data);
      }
    })
  }),
  overrideExisting: true
});

export const {useCreateChipsCountsQuery} = apiWithCreateChipsCounts;
