import React, {FC, forwardRef} from 'react';
import {Box, Button, createFilterOptions, FilterOptionsState, Grid, TextField} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import cn from 'classnames';

import {FormComboBox, FormComboBoxWithConnect} from '@/components/Form/components/FormComboBox';
import {useGetTypesQuery} from '@/stores/api/filtered-dictionaries';
import {useCreateSubtaskMutation} from '@/stores/api/main-page/create-subtasks';
import {ISubtaskResponse} from '@/shared/models/subtaskModel';
import {AddFilesSection} from '@/components/AddFilesSection';
import {useFileState} from '@/shared/hooks/useFileState';
import useLoading from '@/shared/hooks/useLoading';
import {useCompanyLogic} from '@/modals/create/hooks/useCompanyLogic';
import {IDepartment} from '@/shared/models/dictionaryModel';
import {useDepartmentsLogic} from '@/modals/create/hooks/useDepartmentsLogic';
import {useGetUsersQuery} from '@/stores/api/user';
import {IUser} from '@/shared/models';
import {OptionUser} from '@/components/UIKit/OptionUser';
import TextEditor from '@/components/TextEditor/TextEditor';
import {ICompany} from '@/shared/models/companyModel';
import {useAppSelector} from '@/stores/hooks';
import {companyIndividualTypeIdSelector} from '@/stores/TaskConditionsStore/TaskConditionsSelector';
import {checkCompanyIndividual} from '@/shared/utils/companyUtils';

import {useParentTasksLogic} from '../hooks/useParentTasksLogic';

import {FormValues, prepareRequestData} from './utils';

interface IProps {
  parentTaskId?: number;
  defaultCompanyId?: number;
  onClose: () => void;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 786,
  bgcolor: 'background.paper',
  borderRadius: '6px',
  p: 12,
  maxHeight: '100vh',
  overflowY: 'auto'
};

const CreateSubtask: FC<IProps> = forwardRef<HTMLInputElement, IProps>(
  ({parentTaskId, defaultCompanyId, onClose}, _ref) => {
    const companyIndividualType = useAppSelector(companyIndividualTypeIdSelector);
    const [submitCreateTask, {isLoading: isSubmitLoading, isError}] = useCreateSubtaskMutation();

    useLoading(isSubmitLoading);

    const {
      control,
      watch,
      handleSubmit,
      formState: {errors},
      setValue
    } = useForm<FormValues>();

    const watchCompany = watch('company');
    const watchDepartment = watch('department') as IDepartment | undefined;

    /**
     * СПИСОК КЛИЕНТОВ
     */

    const {fetchCompanies, query, isCompaniesLoading} = useCompanyLogic({
      defaultCompanyId,
      setValue,
      queryParams: {
        include: 'company_responsibles',
        'filters[is_has_employees]': 1
      }
    });

    /**
     * Обращения
     */
    const {filteredParentTasks} = useParentTasksLogic({
      companyId: watchCompany?.id,
      parentTaskId,
      setValue
    });
    /**
     * Отделы
     */
    const {departments, isDepartmentsLoading} = useDepartmentsLogic({
      company: watchCompany,
      isTask: false
    });

    /**
     *    Получаем Список типов
     *  - Вставляем отдел подзадачи filters[subtask_types]
     */

    const {types, isTypeLoading} = useGetTypesQuery(
      {
        'filters[subtask_types]': watchDepartment?.id
      },
      {
        skip: !watchDepartment?.id,
        selectFromResult: ({data, isLoading}) => ({
          types: data ? data.filter(val => val.isSubtask) : [],
          isTypeLoading: isLoading
        })
      }
    );

    const {files, handleDeleteFile, handleAttachFiles} = useFileState();

    const {data: employees, isLoading: isEmployeeLoading} = useGetUsersQuery(
      {
        'filters[has_departments]': watchDepartment?.id,
        'filters[active]': 1,
        'filters[is_vacation]': 0
      },
      {
        skip: !watchDepartment?.id
      }
    );

    const onSubmit: SubmitHandler<FormValues> = async data => {
      if (Object.keys(errors).length > 0) {
        return;
      }

      if (data) {
        const requestData = prepareRequestData({...data, attachments: files});
        const result: {data: ISubtaskResponse} | {error: unknown} = await submitCreateTask(
          requestData
        );

        if ('data' in result && result?.data) {
          onClose();
        }
      }
    };

    const filter = createFilterOptions<IUser>({
      trim: true,
      stringify: option => option.name + option.email
    });

    return (
      <Box sx={style}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={12} sx={{marginBottom: 12}}>
            <Grid item xs={12}>
              <label htmlFor="company">
                Клиент <span className="imp">*</span>
              </label>
              <FormComboBoxWithConnect
                id="company"
                name="company"
                required
                isLoading={isCompaniesLoading}
                control={control}
                placeholder="Выберите клиента"
                getData={fetchCompanies}
                query={query}
                renderOption={({className, ...props}: any, option: ICompany) => {
                  const isImp = checkCompanyIndividual(
                    companyIndividualType,
                    option.companyTypeId,
                    option.name
                  );
                  return (
                    <li {...props} className={cn(className, isImp ? 'imp' : '')} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                inputClassName={
                  checkCompanyIndividual(
                    companyIndividualType,
                    watchCompany?.companyTypeId,
                    watchCompany?.name
                  )
                    ? 'imp'
                    : ''
                }
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <label htmlFor="department">
                Отдел <span className="imp">*</span>
              </label>
              <FormComboBox
                id="department"
                name="department"
                required
                isLoading={isDepartmentsLoading}
                options={departments}
                control={control}
                placeholder="Выберите отдел"
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <label htmlFor="employee">
                Бухгалтер <span className="imp">*</span>
              </label>
              <FormComboBox
                id="employee"
                name="employee"
                required
                isLoading={isEmployeeLoading}
                options={employees || []}
                control={control}
                placeholder="Выберите сотрудника"
                renderOption={(props: any, option: IUser) => {
                  return <OptionUser {...props} key={option.id} option={option} />;
                }}
                filterOptions={(options: IUser[], params: FilterOptionsState<IUser>) => {
                  return filter(options, params);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <label htmlFor="title">
                Тема <span className="imp">*</span>
              </label>
              <Controller
                name="title"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Обязательное поле'
                  }
                }}
                defaultValue=""
                render={({field: {onChange, value}}) => (
                  <TextField
                    id="title"
                    placeholder="Укажите название задачи"
                    value={value}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      onChange(event.target.value);
                    }}
                    fullWidth
                    autoComplete="off"
                    error={!!errors?.title}
                    helperText={errors?.title?.message}
                  />
                )}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <label htmlFor="task">По обращению</label>
              <FormComboBox
                id="task"
                name="task"
                options={filteredParentTasks}
                control={control}
                placeholder="Выберите обращение"
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <label htmlFor="taskType">
                Тип <span className="imp">*</span>
              </label>
              <FormComboBox
                id="taskType"
                name="taskType"
                required
                options={types}
                isLoading={isTypeLoading}
                control={control}
                placeholder="Выберите тип обращения"
              />
            </Grid>
            <Grid item xs={12}>
              <label htmlFor="content">
                Описание задачи <span className="imp">*</span>
              </label>
              <Controller
                name="content"
                control={control}
                rules={{
                  required: 'Обязательное поле',
                  validate: value => {
                    const isNotEmpty = value.replace(/<[^>]*>/g, '').trim().length > 0;
                    return (
                      isNotEmpty ||
                      'Описание подзадачи не может быть пустым или состоять только из пробелов'
                    );
                  }
                }}
                defaultValue=""
                render={({field: {onChange, value}}) => (
                  <TextEditor
                    id="content"
                    value={value}
                    onChange={(value: string) => {
                      onChange(value);
                    }}
                    error={!!errors?.content}
                    helperText={errors?.content?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <AddFilesSection
                files={files}
                onDeleteFile={handleDeleteFile}
                onChangeFileInput={handleAttachFiles}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="space-between">
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              loading={isSubmitLoading}
              loadingIndicator="Создание…"
              sx={{minWidth: 154}}
            >
              Создать
            </LoadingButton>
            {isError && <span className="error">Ошибка создания подзадачи</span>}
            <Button
              type="button"
              variant="outlined"
              color="secondary"
              onClick={onClose}
              sx={{minWidth: 154, borderWidth: 2}}
            >
              Отмена
            </Button>
          </Grid>
        </form>
      </Box>
    );
  }
);

CreateSubtask.displayName = 'CreateSubtask';

export default CreateSubtask;
