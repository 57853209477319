import React, {FC, useState} from 'react';
import Button from '@mui/lab/LoadingButton';
import {Typography, useTheme} from '@mui/material';

import {ReactComponent as CreateIcon} from '@/assets/plus-circle.svg';
import {CreateChat} from '@/modals/create/CreateChat';

interface IProps {
  isLoading?: boolean;
}

const CreateButton: FC<IProps> = ({isLoading}) => {
  const theme = useTheme();
  const [isCreateChatOpen, setIsCreateChatOpen] = useState(false);

  const handleCreateChatOpen = () => {
    setIsCreateChatOpen(true);
  };

  const handleCreateChatClose = () => {
    setIsCreateChatOpen(false);
  };

  return (
    <>
      <div className="task-header-button">
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={handleCreateChatOpen}
          loading={isLoading}
          disabled={false}
        >
          <CreateIcon />
          <Typography
            sx={{
              [theme.breakpoints.down('md')]: {
                display: 'none'
              },
              paddingRight: 5,
              paddingLeft: 5
            }}
          >
            Создать чат
          </Typography>
        </Button>

        {isCreateChatOpen && <CreateChat open={isCreateChatOpen} onClose={handleCreateChatClose} />}
      </div>
    </>
  );
};

export default CreateButton;
