export enum ESocketEvent {
  CreateTaskComment = 'create_task_comment',
  UpdateTask = 'update_task',
  CreateSubtaskComment = 'create_subtask_comment',
  UpdateSubtask = 'update_subtask',
  CreateSupportTaskComment = 'create_support_task_comment',
  UpdateSupportTask = 'update_support_task',
  CreateChatMessage = 'create_chat_message',
  UpdateChatMessage = 'update_chat_message',
  UpdateChat = 'update_chat',
  ChangeUnreadChatMessagesCount = 'change_unread_chat_messages_count'
}
