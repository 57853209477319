import React, {FC, SyntheticEvent, useState} from 'react';
import {Box, TextField, Typography} from '@mui/material';
import Button from '@mui/lab/LoadingButton';

import {emailPattern} from '@/shared/constants/emailConstants.ts';

interface EmailStepProps {
  onEmailSubmitted: (email: string) => void;
  isLoading: boolean;
  errorText: string | null;
}

const inputStyles = {
  input: {
    bgcolor: 'auth.main',
    padding: 5
  }
};

const EmailStep: FC<EmailStepProps> = ({onEmailSubmitted, isLoading, errorText}) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState('');

  const handleFocus = () => {
    setEmailError(false);
    setEmailErrorText('');
  };

  const handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!emailPattern.test(email)) {
      setEmailError(true);
      setEmailErrorText('Введите корректный email');
      return;
    }

    onEmailSubmitted(email);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Typography color="white" paddingY={8}>
        Введите email, и вам на почту будет направлено письмо с кодом верификации
      </Typography>
      <Box mb={14}>
        <TextField
          id="email"
          name="email"
          required
          fullWidth
          autoComplete="email"
          placeholder="Введите email"
          color="secondary"
          sx={{
            marginBottom: '2rem',
            ...inputStyles
          }}
          inputProps={{
            onFocus: handleFocus
          }}
          value={email}
          onChange={e => setEmail(e.target.value.toLowerCase())}
          error={emailError}
          helperText={emailErrorText}
        />
      </Box>
      <Button
        type="submit"
        variant="contained"
        loading={isLoading}
        loadingIndicator="Отправка кода…"
        fullWidth
      >
        Отправить код
      </Button>
      <Box textAlign="center" color="error.main">
        <Typography paddingTop={10} variant="body2">
          {errorText || '\u00A0'}
        </Typography>
      </Box>
    </form>
  );
};

export default EmailStep;
