import React, {FC} from 'react';

import {useGetChatByIdQuery} from '@/stores/api/chats';
import useLinearLoading from '@/shared/hooks/useLinearLoading.ts';
import {ChatInfo} from '@/shared/styles/chatStyles';
import {ChatInfoParticipants} from '@/scenes/ChatsPage/components/ChatInfoParticipants';
import ChatInfoCompanies from '@/scenes/ChatsPage/components/ChatInfoCompanies/ChatInfoCompanies';
import ChatInfoTelegram from '@/scenes/ChatsPage/components/ChatInfoTelegram/ChatInfoTelegram';

interface IProps {
  chatId?: string;
}

const ChatInfoSection: FC<IProps> = ({chatId}) => {
  const {
    data: chatData,
    isLoading: isLoadingChat,
    isFetching: isFetchingChat,
    refetch: refetchChat
  } = useGetChatByIdQuery(
    {chatId},
    {
      skip: !chatId,
      refetchOnMountOrArgChange: true
    }
  );

  useLinearLoading(isFetchingChat || isLoadingChat);

  return (
    <ChatInfo>
      <ChatInfoTelegram chatData={chatData} />
      <ChatInfoParticipants
        isLoadingChat={isLoadingChat}
        isFetchingChat={isFetchingChat}
        refetchChat={refetchChat}
        chatUsers={chatData?.chatUsers}
        chatCustomers={chatData?.chatCustomers}
      />
      <ChatInfoCompanies chatData={chatData} />
    </ChatInfo>
  );
};

export default ChatInfoSection;
