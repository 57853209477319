import React, {FC, useCallback, useEffect, useState} from 'react';
import {Backdrop, Box, Button, Grid, Modal, TextField, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';

import {useUpdateCustomerMutation} from '@/stores/api/customers';
import {IUser} from '@/shared/models';

interface IProps {
  open: boolean;
  customer?: IUser;
  setIsEditCustomerOpen: (isOpen: boolean) => void;
  refetchChat: () => void;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  maxWidth: 480,
  bgcolor: 'background.paper',
  borderRadius: '6px',
  p: 18,
  maxHeight: '100vh',
  overflowY: 'auto'
};

const EditCustomerName: FC<IProps> = ({open, customer, setIsEditCustomerOpen, refetchChat}) => {
  const [firstName, setFirstName] = useState<string>(customer?.firstName || '');
  const [lastName, setLastName] = useState<string>(customer?.secondName || '');

  const [updateCustomer, {isLoading: isUpdateLoading}] = useUpdateCustomerMutation();

  const resetInputNames = () => {
    setFirstName(customer?.firstName || '');
    setLastName(customer?.secondName || '');
  };

  const handleSave = useCallback(async () => {
    if (!customer?.id) {
      console.warn('customerId не найден');
      return;
    }

    const firstNamePart = customer?.firstName || '';
    const secondNamePart = customer?.secondName || '';
    const middleName = customer?.middleName
      ? customer.middleName
      : firstNamePart || secondNamePart
      ? `(${[firstNamePart, secondNamePart].filter(Boolean).join(' ')})`
      : '';

    const data = {
      first_name: firstName,
      middle_name: middleName,
      second_name: lastName
    };

    await updateCustomer({customer_id: String(customer.id), data});

    setIsEditCustomerOpen(false);
    resetInputNames();
    refetchChat();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateCustomer, customer, firstName, lastName, setIsEditCustomerOpen, refetchChat]);

  const handleCancel = () => {
    resetInputNames();
    setIsEditCustomerOpen(false);
  };

  useEffect(() => {
    resetInputNames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  return (
    <Modal
      open={open}
      aria-labelledby="Редактирование имени клиента"
      closeAfterTransition
      slots={{backdrop: Backdrop}}
      slotProps={{
        backdrop: {
          timeout: 500
        }
      }}
    >
      <Box sx={style}>
        <Typography sx={{marginBottom: 15}} variant="h2">
          Редактирование клиента
        </Typography>
        <TextField
          label="Имя"
          placeholder="Укажите имя"
          value={firstName}
          onChange={e => setFirstName(e.target.value)}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Фамилия"
          placeholder="Укажите фамилию"
          value={lastName}
          onChange={e => setLastName(e.target.value)}
          fullWidth
          margin="normal"
        />

        <Grid sx={{paddingTop: 15}} container justifyContent="space-between">
          <Grid item>
            <Button
              type="button"
              variant="contained"
              color="error"
              onClick={handleCancel}
              sx={{minWidth: 140}}
            >
              Отмена
            </Button>
          </Grid>
          <Grid item>
            <LoadingButton
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleSave}
              loading={isUpdateLoading}
              loadingIndicator="Сохранение…"
              sx={{minWidth: 140}}
              disabled={!firstName || !lastName}
            >
              Сохранить
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default EditCustomerName;
