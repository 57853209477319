import {api} from '@/stores/api/index.ts';

export const apiWithCustomers = api.injectEndpoints({
  endpoints: builder => ({
    updateCustomer: builder.mutation<
      any,
      {customer_id: string; data: {first_name?: string; middle_name?: string; second_name?: string}}
    >({
      query: ({customer_id, data}) => ({
        url: `/v1/customers/${customer_id}`,
        method: 'PUT',
        data: data
      })
    })
  })
});

export const {useUpdateCustomerMutation} = apiWithCustomers;
