import {api} from 'src/stores/api/index';

interface IGenerateCodeArg {
  email: string;
}

interface IValidateCodeArg {
  code: string;
}

interface IRecoverPasswordArg {
  code: string;
  password: string;
}

export const apiWithForgotPassword = api.injectEndpoints({
  endpoints: builder => ({
    recoveryPassword: builder.mutation<void, IRecoverPasswordArg>({
      query: (data: IRecoverPasswordArg) => ({
        url: '/v1/auth/recovery-password',
        method: 'post',
        data,
        options: {
          skipAuth: true,
          skipMessage: true
        }
      })
    }),
    generateCode: builder.mutation<void, IGenerateCodeArg>({
      query: ({email}: IGenerateCodeArg) => ({
        url: '/v1/auth/recovery-password/code',
        method: 'get',
        params: {
          email
        },
        options: {
          skipAuth: true,
          skipMessage: true
        }
      })
    }),
    validateCode: builder.mutation<void, IValidateCodeArg>({
      query: ({code}: IValidateCodeArg) => ({
        url: '/v1/auth/recovery-password/code',
        method: 'POST',
        data: {
          code
        },
        options: {
          skipAuth: true,
          skipMessage: true
        }
      })
    })
  }),
  overrideExisting: true
});

export const {useValidateCodeMutation, useGenerateCodeMutation, useRecoveryPasswordMutation} =
  apiWithForgotPassword;
