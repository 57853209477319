import {toast} from 'react-toastify';

import {IFile} from '@/shared/models/fileModel';
import {MAX_FILES_CHUNK_LENGTH} from '@/shared/constants/file.ts';

import {toBase64} from './convertBase64';

export default async function prepareAddFiles(fileList: FileList) {
  let maxId = 100;
  const files: IFile[] = [];

  for (const item of fileList) {
    //  50 МБ
    if (item.size < 52428800) {
      const fileBase = (await toBase64(item)) as string;
      const finedIndex = fileBase.indexOf('base64');
      const clearBase = fileBase.substring(finedIndex + 7);
      const file = {
        file: clearBase,
        name: item.name,
        type: item.type,
        size: item.size,
        id: maxId++
      };
      files.push(file);
    } else {
      toast.error('Размер файла больше 50Mb', {
        theme: 'colored'
      });
    }
  }

  return files as IFile[];
}

export const sendWithFileChunks = async <TParams>({
  content,
  attachments,
  submitFunction,
  submitParams,
  splitFiles = true
}: {
  content: string;
  attachments: IFile[];
  submitFunction: (params: TParams) => Promise<any>;
  submitParams: Omit<TParams, 'content' | 'attachments'>;
  splitFiles?: boolean;
}) => {
  if (splitFiles && attachments.length > MAX_FILES_CHUNK_LENGTH) {
    const numChunks = Math.ceil(attachments.length / MAX_FILES_CHUNK_LENGTH);
    for (let i = 0; i < numChunks; i++) {
      const chunkFiles = attachments.slice(
        i * MAX_FILES_CHUNK_LENGTH,
        i * MAX_FILES_CHUNK_LENGTH + MAX_FILES_CHUNK_LENGTH
      );

      const params: TParams = {
        ...submitParams,
        content: i === 0 ? content : '',
        attachments: chunkFiles
      } as TParams;

      await submitFunction(params);
    }
  } else {
    const params: TParams = {
      ...submitParams,
      content,
      attachments: attachments
    } as TParams;

    await submitFunction(params);
  }
};
