import React, {FC, HTMLAttributes, useCallback, useMemo} from 'react';
import {Checkbox, Chip} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import {ComboBox} from '@/components/UIKit';
import {useAppDispatch, useAppSelector} from '@/stores/hooks';
import {IUser} from '@/shared/models';
import {
  taskCompanySelector,
  taskEmployeeSelector,
  toCopyEmployeesSelector,
  updateToCopyEmployees
} from '@/stores/TaskSettingsStore';
import {useGetEmployeesQuery} from '@/stores/api/employees';
import {ITaskUpdateDataRequest} from '@/shared/models/tasksDataModel';
import {MetaListItem} from '@/components/MetaListItem';

interface IProps {
  isDisabled?: boolean;
  onUpdateTask: (editedParams: ITaskUpdateDataRequest) => void;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const RecipientInputCopy: FC<IProps> = ({isDisabled, onUpdateTask}) => {
  const dispatch = useAppDispatch();
  const editedCompany = useAppSelector(taskCompanySelector);
  const editedEmployee = useAppSelector(taskEmployeeSelector);
  const toCopyEmployees = useAppSelector(toCopyEmployeesSelector);

  const {data: employeeList = [], isLoading} = useGetEmployeesQuery(
    {
      filters: {
        company_id: editedCompany?.id,
        active: 1,
        bitrix_api_id: 1,
        is_telegram: 0
      }
    },
    {
      skip: !editedCompany?.id
    }
  );

  const handleChange = (values?: IUser[] | null) => {
    // @ts-ignore
    dispatch(updateToCopyEmployees(values));

    const ids = values ? values.map(val => val.id) : [];
    handleSendCopies(ids);
  };

  const handleSendCopies = useCallback(
    (ids: number[]) => {
      const params: ITaskUpdateDataRequest = {
        to_copy_employees_ids: ids
      };

      onUpdateTask(params);
    },

    [onUpdateTask]
  );

  const employeeListWithoutMe = useMemo(() => {
    if (!editedEmployee?.id) {
      return employeeList;
    }
    return employeeList.filter(({id}) => id !== editedEmployee.id);
  }, [editedEmployee?.id, employeeList]);

  if (isDisabled) {
    return (
      <div>
        <MetaListItem field="Копия">
          {toCopyEmployees.map((o: IUser) => o.name).join(', ') || '-'}
        </MetaListItem>
      </div>
    );
  }

  return (
    <ComboBox<IUser>
      disabled={isDisabled}
      options={employeeListWithoutMe}
      isLoading={isLoading}
      variantInput="standard"
      fieldLabel="Копия"
      multiple
      sx={{
        minWidth: '240px'
      }}
      disableClearable
      value={toCopyEmployees}
      onChange={(_, newValues) => {
        // @ts-ignore
        handleChange(newValues);
        // return newValues;
      }}
      renderOption={(
        props: HTMLAttributes<HTMLLIElement>,
        option: IUser,
        {selected}: {selected: boolean}
      ) => (
        <li {...props} key={option.id + option.email}>
          <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
          {`${option.name} (${option.email})`}
        </li>
      )}
      renderTags={(values: IUser[], getTagProps) => {
        return values.map((option: IUser, index) => (
          // eslint-disable-next-line react/jsx-key
          <Chip
            variant="outlined"
            label={`${option.name} (${option.email})`}
            {...getTagProps({index})}
          />
        ));
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
    />
  );
};

export default RecipientInputCopy;
