import {useMemo} from 'react';

import {userInfoSelector} from '@/stores/AuthStore/AuthStateSelector';
import {useAppSelector} from '@/stores/hooks';

export const useResponsiblesFilters = () => {
  const currentUser = useAppSelector(userInfoSelector);

  const unitIds = useMemo(() => {
    if (!currentUser?.userUnits) {
      return;
    }

    return currentUser.userUnits
      .map(unit => {
        return unit.unitId;
      })
      .join(',');
  }, [currentUser]);

  return useMemo(() => {
    if (!currentUser) {
      return {};
    }

    /**
     * возможность сотрудникам техподдержки получить список
     * клиентов при создании задачи на странице техподдержки
     */
    const isSupportDepartment = currentUser.userDepartments?.some(
      department => department.departmentId === 26
    );

    /**
     * возможность кросс-юнитовым пользователям создавать задачи в ТП
     */
    const isCrossUnit = !currentUser.userUnits && currentUser.userDepartments;

    if (isSupportDepartment || isCrossUnit) {
      return {
        'filters[or_inner_support_company]': 2
      };
    }

    /**
     * в селекторе “Клиент” бухгалтер видит только тех клиентов к которым привязан бухгалтер,
     * руководитель отдела видит только тех клиентов к которым привязаны бухгалтера его отдела:
     */
    const isHead = currentUser.isHead;
    const isBuh = !currentUser.isHead && !currentUser.isHeadUnit;

    if (isBuh || isHead) {
      return {
        'filters[my_companies]': `${currentUser.id}`
      };
    }

    /**
     * руководитель юнита видит всех клиентов в рамках привязанных к нему юнитов:
     * Если is_head_unit=True подставляем в фильтр filters[unit_id]=in| все id через запятую из массива в токене user_units
     */

    const isHeadUnit = currentUser.isHeadUnit;

    if (isHeadUnit && unitIds) {
      return {
        'filters[unit_id]': 'in|' + unitIds
      };
    }

    return {};
  }, [currentUser, unitIds]);
};
