import React, {FC, FocusEvent, SyntheticEvent, useState} from 'react';
import {Box, TextField, Typography} from '@mui/material';
import Button from '@mui/lab/LoadingButton';

interface PasswordStepProps {
  onPasswordSubmitted: (password: string) => void;
  isLoading: boolean;
  errorText: string | null;
}

const inputStyles = {
  input: {
    bgcolor: 'auth.main',
    padding: 5
  }
};

const PasswordStep: FC<PasswordStepProps> = ({onPasswordSubmitted, isLoading, errorText}) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');

  const handleSetPassword = (value: string) => {
    setPassword(value);

    if (value.length < 6) {
      setPasswordError('Пароль должен быть не менее 6 символов.');
    } else {
      setPasswordError('');
    }

    if (confirmPassword && value !== confirmPassword) {
      setConfirmPasswordError('Пароли не совпадают.');
    } else {
      setConfirmPasswordError('');
    }
  };

  const handleSetConfirmPassword = (value: string) => {
    setConfirmPassword(value);

    if (value.length < 6) {
      setConfirmPasswordError('Пароль должен быть не менее 6 символов.');
    } else if (value !== password) {
      setConfirmPasswordError('Пароли не совпадают.');
    } else {
      setConfirmPasswordError('');
    }
  };

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    const field = e.target.name;
    if (field === 'password') {
      setPasswordError('');
    } else if (field === 'confirmPassword') {
      setConfirmPasswordError('');
    }
  };

  const handleSubmit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!passwordError && !confirmPasswordError) {
      onPasswordSubmitted(password);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box mb={14} paddingTop={8}>
        <TextField
          type="password"
          id="password"
          name="password"
          required
          fullWidth
          placeholder="Новый пароль"
          autoComplete="new-password"
          color="primary"
          sx={{
            minWidth: '400px',
            marginBottom: '2rem',
            ...inputStyles
          }}
          inputProps={{
            onFocus: handleFocus
          }}
          value={password}
          onChange={e => handleSetPassword(e.target.value)}
          error={!!passwordError}
          helperText={passwordError || '\u00A0'}
        />
        <TextField
          type="password"
          id="confirmPassword"
          name="confirmPassword"
          required
          fullWidth
          placeholder="Подтвердите новый пароль"
          autoComplete="new-password"
          color="primary"
          sx={{
            minWidth: '400px',
            ...inputStyles
          }}
          inputProps={{
            onFocus: handleFocus
          }}
          value={confirmPassword}
          onChange={e => handleSetConfirmPassword(e.target.value)}
          error={!!confirmPasswordError}
          helperText={confirmPasswordError || '\u00A0'}
        />
      </Box>
      <Button
        type="submit"
        variant="contained"
        loading={isLoading}
        loadingIndicator="Смена пароля…"
        fullWidth
      >
        Сменить пароль
      </Button>
      <Box textAlign="center" color="error.main">
        <Typography paddingTop={10} variant="body2">
          {errorText || '\u00A0'}
        </Typography>
      </Box>
    </form>
  );
};

export default PasswordStep;
