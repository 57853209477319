import {Button, Paper, styled, Typography} from '@mui/material';
import {LoadingButton} from '@mui/lab';

export const ChatList = styled(Paper)(({theme}) => ({
  backgroundColor: theme.palette.background.paper,
  width: '25%',
  overflowY: 'auto',
  alignItems: 'center',
  boxSizing: 'border-box',
  position: 'relative'
}));

export const ChatHeader = styled(Paper)(({theme}) => ({
  backgroundColor: 'rgba(19, 36, 54, 0.9)',
  height: '8%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(6),
  boxSizing: 'border-box',
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  top: -1,
  zIndex: 1
}));

export const ChatInfo = styled(Paper)(({theme}) => ({
  backgroundColor: theme.palette.background.paper,
  width: '25%',
  overflowY: 'auto',
  alignItems: 'center',
  boxSizing: 'border-box',
  position: 'relative'
}));

export const ChatHeaderButton = styled(Button)(() => ({
  color: 'var(--nav-color-inactive)',
  transition: 'color 0.2s ease-in-out',

  '&:hover': {
    color: 'white'
  }
}));

export const ChatInfoButton = styled(Button)(() => ({
  backgroundColor: 'rgba(19, 36, 54, 0.9)',
  transition: 'background-color 0.2s ease-in-out, color 0.2s ease-in-out',

  '&:hover': {
    backgroundColor: 'rgba(19, 36, 54, 1)',
    color: 'white'
  }
}));

export const ChatInfoLoadingButton = styled(LoadingButton)(() => ({
  backgroundColor: 'rgba(19, 36, 54, 0.9)',
  transition: 'background-color 0.2s ease-in-out, color 0.2s ease-in-out',

  '&:hover': {
    backgroundColor: 'rgba(19, 36, 54, 1)',
    color: 'white'
  }
}));

export const BlackHr = styled('hr')(({theme}) => ({
  width: '100%',
  border: 'none',
  borderTop: '1px solid black',
  margin: theme.spacing(4, 0)
}));

export const DividerHr = styled('hr')(({theme}) => ({
  width: '100%',
  border: 'none',
  borderTop: `2px solid ${theme.palette.divider}`,
  margin: theme.spacing(4, 0)
}));

export const EllipsisTypography = styled(Typography)(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: '100%'
}));
