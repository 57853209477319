import {ICompany} from '@/shared/models/companyModel';

export type FormValues = {
  company: ICompany;
  telegram_invite_link?: string;
};

export const prepareRequestData = (data: FormValues) => {
  const {company, telegram_invite_link} = data;

  return {
    company_id: company.id,
    telegram_invite_link: telegram_invite_link
  };
};
