import React, {FC, useCallback, useEffect, useState} from 'react';
import {Typography} from '@mui/material';

interface IProps {
  hours?: number;
  minutes?: number;
  seconds: number;
  needUpdate?: boolean;
  onDone: () => void;
}

const Countdown: FC<IProps> = ({hours = 0, minutes = 0, seconds = 60, needUpdate, onDone}) => {
  const [[hrs, mins, secs], setTime] = useState([hours, minutes, seconds]);

  const reset = useCallback(() => {
    setTime([hours, minutes, seconds]);
  }, [hours, minutes, seconds]);

  const tick = () => {
    if (hrs === 0 && mins === 0 && secs === 0) {
      onDone();
    } else if (mins === 0 && secs === 0) {
      setTime([hrs - 1, 59, 59]);
    } else if (secs === 0) {
      setTime([hrs, mins - 1, 59]);
    } else {
      setTime([hrs, mins, secs - 1]);
    }
  };

  useEffect(() => {
    const timerId = setInterval(() => tick(), 1000);
    return () => clearInterval(timerId);
  });

  useEffect(() => {
    if (needUpdate) {
      reset();
    }
  }, [needUpdate, reset]);

  if (hours > 0) {
    return (
      <Typography>
        {`${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs
          .toString()
          .padStart(2, '0')}`}
      </Typography>
    );
  } else if (minutes > 0) {
    return (
      <Typography>{`${mins.toString().padStart(2, '0')}:${secs
        .toString()
        .padStart(2, '0')}`}</Typography>
    );
  } else {
    return <Typography>{`${secs.toString().padStart(2, '0')}`}</Typography>;
  }
};

export default Countdown;
